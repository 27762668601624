/* website: 3115-kentvilletoyota
 * created at 2022-07-06 12:00 by Rymma
 */

// Import all makes and organization styles files
@import "../templates/makes/toyota.scss";
@import "../utils/icons.scss";

@include breakpoint(mobile){
  .widget-sr {
    &.dealer__kentville-toyota {
      /** chat showing ctas buiklder with chat **/
      .showroom-build-and-price .btn-step{
        width: 50%;
        margin: 0 0 48px 0;
      }
      .listing-tiles-payment{
        .financing-section{
          &.tile-payment-info{
            .tile-payment{
              .tile-payment-detail.small{
                .total-cost-of-credit{
                  display: none;
                }
              }
            }
          }
        }
      }
    }
  }
}

.widget-sr{
  &.dealer__kentville-toyota{
    /** fix place tradein at top of listing **/
    .listing-used-car-search>div:last-child{
      display: flex;
      flex-direction: column;
      #instant-estimate-bar-wrapper{
        order: 0;
        margin-top: 0px !important;
      }
      .listing-tiles{
        order: 1;
      }
      .listing-used-button-loading.sr-button-1{
        order: 2;
      }
    }
    .listing-tiles-payment{
      .financing-section{
        &.tile-payment-info{
          .tile-payment{
            .tile-payment-detail.small{
              .total-cost-of-credit{
                display: none;
              }
            }
          }
        }

      }
    }

  }
}
